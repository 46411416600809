import { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Step1 from '../Assets/love1.gif';
import Step2 from '../Assets/love2.gif';

function Proposal({ nextStep }) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    showNext(step);
  }, []);

  const showNext = () => {
    const time = 2000;
    setTimeout(() => {
      setStep(1);
    }, time);
  }

  return (
    <>
      <div className='card-wrapper'>
        {step === 0 && <img src={Step1} className='image-full'  />}
        {step === 1 && <div>
          <h2>WILL YOU BE MY BETTER HALF ?</h2>
          <label className='my-2 mb-4'>You are everything that I have been looking for, and I never want to let you go. Will you be mine forever? </label>
          <img src={Step2} className='image-full'  />
        </div>}
      </div>
    </>
  );
}

export default Proposal;
