import { useEffect, useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import WRONG from '../Assets/wrong.gif';
import RIGHT from '../Assets/right.gif';
import { NAME, DATE } from '../constant';
import Looking from '../Assets/looking.png';

function ValidatePerson({ nextStep }) {
  const inputRef = useRef(null);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const onSubmit = () => {
    if(show) return;
    if (inputRef?.current?.value == DATE) {
      setShow(true);
      setIsValid(true);
      setTimeout(() => {
        setShow(false);
        nextStep();
      }, 5000);

    } else {
      setShow(true);
      setIsValid(false);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }

  }
  return (
    <>
      <div className='card-wrapper'>
        <div>
        <img src={Looking} className='looking'/>
        </div>
        <h4 className='my-4'>Are you the one I am looking for ?</h4>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control ref={inputRef} type="number" placeholder="" />
          <Form.Label className='my-2'>Type your special date in format: ddmmyyyy</Form.Label>
        </Form.Group>
        <Button as="a" variant="primary" onClick={onSubmit} disabled={show}>
          Click here
        </Button>
      </div>
      {
        show && <div className='alertCard'>
          {isValid ? (
            <div>
              <h4 className='my-3'>I am happy to be a part of your special day (14th september) {NAME}.</h4>
              <img src={RIGHT} className='image-full' />
            </div>
          ) : (
            <div>
              <h3>Sorry, You are not the right person !!!</h3>
              <img src={WRONG} className='image-full' />
            </div>
          )
          }
        </div>
      }
    </>
  );
}

export default ValidatePerson;
