import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Step1 from '../Assets/step1.gif';
import Step2 from '../Assets/step2.gif';
import Step3 from '../Assets/step3.gif';
import { NAME } from '../constant';

function Wishes({ nextStep }) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    showNext(step);
  }, []);

  const showNext = () => {
    const time = 2000;
    setTimeout(() => {
      setStep(1);
    }, time);
    setTimeout(() => {
      setStep(2);
    }, time * 5);
  }

  return (
    <>
      <div className='card-wrapper'>
        {step === 0 && <img src={Step1} className='image-full' />}
        {step === 1 && <div>
          <h2>I wish you many happy returns of the day</h2>
          <h3>{NAME}</h3>
          <img src={Step2} className='image-full' />
          </div>}
        {step === 2 && <div>
          <h2>HAPPY BITHDAY</h2>
          <h1>{NAME}</h1>
          <label className='my-2 mb-4'>Your birthday is the first day of another 365-day journey. Be the shining thread in the beautiful tapestry of the world to make this year the best ever. Enjoy the ride.</label>
          <Button as="a" variant="primary" className='mb-4' onClick={nextStep}>Click here</Button>
          <img src={Step3} className='image-full'/>
        </div>}
      </div>
    </>
  );
}

export default Wishes;
