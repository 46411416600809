import { useState } from 'react';
import './App.css';
import './bubbles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ValidatePerson from './components/ValidatePerson';
import WishesComponent from './components/wishes';
import ProposalComponent from './components/proposal';

function App() {
  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(pre => pre + 1);
  };

  return (
    <>
      <div className='body-container'>
          {step === 0 ? <ValidatePerson nextStep={nextStep} />: null }  
          {step === 1 ? <WishesComponent nextStep={nextStep} />: null }    
          {step === 2 ? <ProposalComponent />: null }                                
      </div>
      <section className="sticky">
        <div className="bubbles">
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>
          <div className="bubble"></div>

        </div>
      </section>
    </>
  );
}

export default App;
